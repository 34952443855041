<template>
	<div class="action-overview">
		<OverviewFilter
				v-if="showFilter"
				:options="filterOptions"
				search-placeholder="Zoek op risico"
				@show-filter-dialog="$emit('open-filter')"
		/>

		<OverviewSummary
				v-if="showCount"
				:total="filteredItems.length"
				type="acties"
		/>

		<OverviewHeader
				v-if="showHeaders && actions.length > 0"
				:headers="headerConfig"
				:sorting="sorting"
				@sort="updateSorting"
		/>
		<ActionOverviewItem
				v-for="action in limitedActions"
				:key="action.id"
				:action="action"
				:rie-id="rieId"
				:company-id="String(companyId)"
		/>
	</div>
</template>
<script lang="ts" setup>
	import {computed, type PropType, type ComputedRef} from 'vue';
	import type {ActionDecorator, FlatActionOverviewItem} from '~/lib/types/actionTypes';
	import ActionOverviewItem from '~/components/actions/ActionOverviewItem.vue';
	import OverviewHeader from '~/components/generic/overview/OverviewHeader.vue';
	import OverviewSummary from '~/components/generic/overview/OverviewSummary.vue';
	import {defaultSortingFn, type Sorting, useSorting} from '~/composables/useSorting';
	import {getProcessedActionStatus} from '~/lib/actionHelper';
	import OverviewFilter from '~/components/generic/overview/filter/OverviewFilter.vue';
	import {useFilter} from '~/composables/useFilter';
	import {useActionOverview} from '~/composables/useActionOverview';

	const props = defineProps({
		actions: {
			type: Array as PropType<ActionDecorator[]>,
			default: () => []
		},
		showCount: {
			type: Boolean,
			default: false
		},
		showHeaders: {
			type: Boolean,
			default: false
		},
		showFilter: {
			type: Boolean,
			default: false
		},
		limit: {
			type: Number,
			default: undefined
		},
		rieId: {
			type: String,
			required: false,
			default: undefined
		},
		companyId: {
			type: [Number, String],
			required: false,
			default: undefined
		},
		sortable: {
			type: Boolean,
			default: true
		}
	});

	defineEmits(['open-filter']);

	const headerConfig = [
		{
			text: 'Actieomschrijving',
			class: 'description',
			key: 'description',
			sortable: props.sortable
		},
		{
			text: 'Actie datum',
			key: 'endDate',
			class: 'date',
			sortable: props.sortable
		},
		{
			text: 'Verantwoordelijke',
			class: 'responsible',
			key: 'responsible',
			sortable: props.sortable
		},
		{
			text: 'Status',
			class: 'status',
			key: 'status',
			sortable: props.sortable
		}
	];

	const defaultSorting = {
		key: 'endDate',
		value: 'asc'
	} satisfies Sorting;

	const {filterOptions} = useActionOverview();

	const flatActions: ComputedRef<FlatActionOverviewItem[]> = computed(() => {
		return props.actions.map(action => {
			return {
				id: action.id,
				description: action.description.text,
				endDate: action.endDate,
				responsible: action.responsible,
				status: action.status,
				repeat: action.repeat,
				filterStatus: getProcessedActionStatus(action).text
			};
		});
	});

	const {
		sortedItems,
		updateSorting,
		sorting
	} = useSorting<FlatActionOverviewItem>(
		flatActions,
		defaultSorting,
		['description', 'endDate', 'responsible', 'status'],
		{
			status: (a, b, direction) => {
				const statusA = getProcessedActionStatus(a);
				const statusB = getProcessedActionStatus(b);

				return defaultSortingFn(statusA.order, statusB.order, direction);
			}
		}
	);

	const {filteredItems} = useFilter<FlatActionOverviewItem>(sortedItems, filterOptions);

	const limitedActions = computed(() => {
		if (props.limit) {
			return filteredItems.value.slice(0, props.limit);
		}
		return filteredItems.value;
	});
</script>

<style scoped>
	.action-overview {
		background: #ffffff;
		--action-overview-grid-desktop: "description date responsible status cta" auto / 1fr 116px 146px 136px 78px;
		--action-overview-grid-mobile: "date date" auto "responsible responsible" auto "description description" auto "status cta" auto / 1fr;

		--action-overview-grid: var(--action-overview-grid-mobile);
		--overview-header-grid: var(--action-overview-grid);
		--overview-header-display: none;

		--cell-padding-y: 20px;
		--cell-padding-x: 10px;

		@media screen and (min-width: 870px) {
			--action-overview-grid: var(--action-overview-grid-desktop);
			--overview-header-display: grid;
		}

		@media screen and (min-width: 1040px) {
			--cell-padding-y: 20px;
			--cell-padding-x: 20px;
		}
	}
</style>
