import {type ComputedRef, ref, watch} from 'vue';
import type {FilterOptions} from '~/lib/filter/filterTypes';
import type {FlatActionOverviewItem} from '~/lib/types/actionTypes';

const filterOptions = ref<FilterOptions<FlatActionOverviewItem>>({
	search: {
		value: '',
		keys: ['description', 'responsible']
	},
	filters: [
		{
			label: 'Verantwoordelijke',
			type: 'select',
			key: 'responsible',
			value: '',
			options: []
		},
		{
			label: 'Herhaling',
			type: 'checkbox',
			key: 'repeat',
			values: [],
			options: [
				{
					label: 'Niet herhalend',
					value: 'NON_REPEATING'
				},
				{
					label: 'Herhaling per maand',
					value: 'MONTHLY'
				},
				{
					label: 'Herhaling per kwartaal',
					value: 'QUARTERLY'
				},
				{
					label: 'Herhaling per jaar',
					value: 'YEARLY'
				}
			]
		},
		{
			label: 'Status',
			type: 'checkbox',
			key: 'filterStatus',
			values: [],
			options: [
				{
					label: 'Verlopen',
					value: 'Verlopen'
				},
				{
					label: 'Bijna verlopen',
					value: 'Bijna verlopen'
				},
				{
					label: 'Open',
					value: 'Open'
				}
			]
		}
	]
});

export const useActionOverview = (responsibles?: ComputedRef<Array<string>>) => {
	if (responsibles) {
		watch(responsibles, () => {
			filterOptions.value.filters[0].options = responsibles.value.map(responsible => ({
				value: responsible,
				label: responsible
			}));
		});
	}

	return {
		filterOptions
	};
};
